import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

import Layout from '../components/Layout';
import UserMessage from '../components/UserMessage';

const mapStateToPropsSelector = createSelector(
  (state) => state.settingsReducer,
  ({ redirectTo }) => redirectTo
);

const LogOutPage = () => {
  const [counter, setCounter] = useState(3);
  useEffect(() => {
    let timeout = null;

    if (counter > 0) {
      timeout = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }

    return () => clearTimeout(timeout);
  }, [counter]);

  const redirectTo = useSelector(mapStateToPropsSelector);
  useEffect(() => {
    if (counter === 0) {
      navigate(redirectTo);
    }
  }, [counter, redirectTo]);

  return (
    <Layout>
      <UserMessage type="logOut" counter={counter} />
    </Layout>
  );
};

export default LogOutPage;
